export default {
    accountsCompanyId: {
        component: 'text-field',
        autocomplete: {
            type: 'company',
        },
        label: 'Accounts Company',
        cols: 4,
        clearable: true,
    },
    'vendor.code': {
        component: 'text-field',
        label: 'Vendor Name',
        autocomplete: {
            type: 'vendorCode',
        },
    },
    blank1: {
        component: 'blank-field',
        cols: 2,
    },


    'company.code': { component: 'text-field', label: 'Company', cols: 4 },

    documentReference: { component: 'text-field', label: 'Reference', cols: 4 },

    documentDate: {
        label: 'Doc Date',
        component: 'text-field', cols: 4,
    },
    dueDate: {
        label: 'Due Date',
        component: 'text-field', cols: 4,
    },
    documentTotal: {
        component: 'currency-field',
        label: 'Bill Total',
        cols: 4,
    },
};
