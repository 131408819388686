<template>
    <Page title="Vendor Bill" icon="mdi-cash-minus">
        Not a correct page
        <smart-form
            v-if="formModel"
            v-model="formModel"
            :schema="formSchemaMain"
            form-code="account-detail"
        />
        <smart-table
            v-if="tableItems.length"
            :headers="tableHeaders"
            :items="tableItems"
            :items-per-page="20"
            class="elevation-1"
            dense
            isFilter
        />
        <toolbar
            :left-items="[]"
            :right-items="[
                {
                    preset: 'done',
                    onClick: toListView,
                    size: 'small',
                },
            ]"
        />
    </Page>
</template>
<script>
import api from '@/api';

import formSchemaMain from '@/forms/billDetail/main';

const tableHeaders = [
    {
        text: 'Item',
        align: 'left',
        sortable: true,
        value: 'item.code',
    },
    {
        text: 'Source',
        align: 'left',
        sortable: true,
        value: 'source.entityName',
    },
    {
        text: 'Cost Centre',
        align: 'left',
        sortable: true,
        value: 'costCentre.code',
    },
    {
        text: 'description',
        align: 'left',
        sortable: true,
        value: 'description',
    },
    {
        text: 'Amount',
        align: 'left',
        sortable: true,
        value: 'amount',
    },
    {
        text: 'Ex Tax',
        align: 'left',
        sortable: true,
        value: 'extaxAmount',
    },
    {
        text: 'Tax Status',
        align: 'left',
        sortable: true,
        value: 'taxStatus',
    },
    {
        text: 'Comment',
        align: 'left',
        sortable: true,
        value: 'comment',
    },
];

export default {
    name: 'BillDetail',
    data() {
        return {
            formModel: null,
            formSchemaMain,
            tableItems: [],
            tableHeaders,
        };
    },
    methods: {
        async getFormData() {
            const id = this.$route.params.id;
            const source = this.$route.params.source;
            const result = await api.get(this.$store, `bill/${source}/${id}`);
            this.formModel = { ...result.data };
            // console.log('getForm', this.formModel);
            this.tableItems = result.data ? result.data.lines : [];
        },
        logForm() {
            console.log(JSON.stringify(this.formModel, null, 2));
        },
        toListView() {
            this.$router.push('/auth/admin/bill');
        },
    },
    async mounted() {
        // console.log('this.$route.params', this.$route.params);
        await this.getFormData();
    },
};
</script>

