var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"Vendor Bill","icon":"mdi-cash-minus"}},[_vm._v(" Not a correct page "),(_vm.formModel)?_c('smart-form',{attrs:{"schema":_vm.formSchemaMain,"form-code":"account-detail"},model:{value:(_vm.formModel),callback:function ($$v) {_vm.formModel=$$v},expression:"formModel"}}):_vm._e(),(_vm.tableItems.length)?_c('smart-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"items-per-page":20,"dense":"","isFilter":""}}):_vm._e(),_c('toolbar',{attrs:{"left-items":[],"right-items":[
            {
                preset: 'done',
                onClick: _vm.toListView,
                size: 'small',
            },
        ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }